import {initVideos} from "./videos";
import {videoSliderExpandAnimation} from "./animation/video-slider-expand-animation";
import {welcomeSliderSettings} from "./sliders/welcome-slider";
import {ourProjectsSliderSettings} from "./sliders/our-projects-slider";
import {activateItemExpand, deactivateItemExpand} from "./animation/item-expand-animation";
import {closeItemAnimation, setCurrentItemAnimation} from "./animation/items-animation";
import {scrollTrigger} from "./animation/animation-on-scroll";
import {isRTL} from "./utilities/isRTL";
import {getCookie, inquiryModal, setCookie} from "./utilities/cookie.js";
import counterUp from "counterup2";
import {Fancybox} from "@fancyapps/ui";

export const runApp = () => {
    initVideos();
    $('.navbar-toggler').click(function () {
        $('header').toggleClass('active');
    });
    Fancybox.bind("[data-fancybox=gallery]", {
        // Your custom options
    });
    let mcmGalleryOptions = {
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: $(".mcm-gallery-slider-arrows .prev"),
        nextArrow: $(".mcm-gallery-slider-arrows .next"),
        rtl: false
    };
    $(".mcm-gallery-slider").slick(mcmGalleryOptions);
    $('.videos-slider-wrapper .slider').slick(videoSliderExpandAnimation.sliderSettings());
    $('.northed-2-welcome .slider').slick(welcomeSliderSettings('northed-2-welcome-slider'))
    $(".our-project-slider").slick(ourProjectsSliderSettings('our-project-slider-wrapper'));
    $('button[data-bs-toggle="pill"]').on('shown.bs.tab', () => {
        $('.slider').slick('setPosition');
    })

    $('.expand-animation .slider').each((index, element) => {
        const $slider = $(element);
        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            speed: 1000,
            autoplaySpeed: 3000,
            prevArrow: $slider.closest('.slider-wrapper').find(".slider-arrows .prev"),
            nextArrow: $slider.closest('.slider-wrapper').find(".slider-arrows .next"),
            infinite: false,
            rtl: false
        });
    });

    $(".videos-slider-wrapper .slide").on('click', (el) => {
        videoSliderExpandAnimation.openVideo(el, 'northed-videos')
    })

    /*  $(".apartment-plans .apartment-plans-close").on('click', () => {
          $('.apartment-plans').removeClass('active');
          $(".northed-2-welcome-content").get(0).scrollIntoView({behavior: 'smooth'});
      })

      $('.open-apartment-plans').on('click', () => {
          $('.apartment-plans').addClass('active')
          $(".apartment-plans-content").get(0).scrollIntoView({behavior: 'smooth'});
      })*/
    $(".open-apartment-plans-list").on('click', () => {
        $(".northed-2-welcome").toggleClass('active');
    })

    $('.back-expand-animation').on('click', (el) => {
        deactivateItemExpand(el)
    })
    $('.item-expand').on('click', (el) => {
        activateItemExpand(el)
    })

    $('.items-animation .item').on('click', (el) => {
        setCurrentItemAnimation(el)
    })
    $('.items-animation .close-item').on('click', (el) => {
        closeItemAnimation(el)
    })
    $('.expanding-opportunities-tabs ul li button').on('click', (el) => {
        const id = $(el.currentTarget).attr('data-video-relation-id');
        $(el.currentTarget).addClass('active');
        $('.expanding-opportunities').addClass('active');
        $('.expanding-opportunities .video-wrapper .video-bg-map').removeClass('active');
        $(`.expanding-opportunities .video-wrapper .video-bg-map[id="video-${id}"]`).addClass('active');
        $('.expanding-opportunities .expanding-opportunities-close').attr('data-video-relation-id', id);
        $(`#video-${id} video`)[0].currentTime = 0;
        $(`#video-${id} video`)[0].play();
    })
    $('.expanding-opportunities .expanding-opportunities-close').on('click', (el) => {
        $('.expanding-opportunities,' +
            ' .expanding-opportunities-tabs ul li button,' +
            ' .expanding-opportunities-places .tab-pane')
            .removeClass('active show');

        const id = $(el.currentTarget).attr('data-video-relation-id');
        $('.expanding-opportunities .video-wrapper .video-bg-map').removeClass('active');
        $(`.expanding-opportunities .video-wrapper .video-bg-map[id="video-${id}-out"]`).addClass('active');
        $(`#video-${id}-out video`)[0].currentTime = 0;
        $(`#video-${id}-out video`)[0].play();
    })

    window.closeSliderVideo = (el) => {
        videoSliderExpandAnimation.closeVideo(el)
    };


    scrollTrigger('.in-viewport', {
        rootMargin: "-100px",
    });

    $('.input-file input').on('change', (e) => {
        $(e.target).closest('.input-file').find('.file-area').text(e.target.files[0].name);
    })

    $('.press-releases .options button').on('click', event => {
        $('.press-releases').addClass('active');
    });

    $('.our-sales-offices li button').on('click', () => {
        $('.our-sales-offices').addClass('active');
    })

    $('.sparking-happiness li button').on('click', () => {
        $('.sparking-happiness').addClass('active');
    })

    if (getCookie(inquiryModal)) {
        if (getCookie(inquiryModal) < 2) {
            setCookie(inquiryModal, 2, 1)
            $('#mainOpenInquiryModal').click();
        }
    } else {
        setCookie(inquiryModal, 1, 1);
        $('#mainOpenInquiryModal').click();
    }

    $('.item-expand.is-slider').each((index, item) => {
        let numberOfSlides = $(item).find('.slide').length;
        if (numberOfSlides === 0) {
            $(item).addClass('no-slides');
        }
    });

    $('.career-open').on('click', () => {
        $('.careers').toggleClass('active');
        $('footer').toggleClass('career-active');
    })

    $('.careers .close-careers').on('click', () => {
        $('.careers').toggleClass('active');
        $('footer').toggleClass('career-active');
    })

    $('.full-projects').on('click', (e) => {
        if (window.innerWidth <= 768 && !$('.full-projects').hasClass('active')) {
            $('.full-projects').addClass('active');
            setTimeout(() => {
                $('.full-projects .counter').each((index, item) => {
                    counterUp(item, {
                        duration: 1000,
                        delay: 16,
                    })
                })
            }, 1000)
        }
    })

    $('.construction-updates-section .slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 3000,
        prevArrow: $(".construction-updates-section .slider-arrows .prev"),
        nextArrow: $(".construction-updates-section .slider-arrows .next"),
        infinite: true,
        centerMode: true,
        centerPadding: '20rem',
        rtl: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    centerPadding: '0',
                }
            }
        ]
    });
}